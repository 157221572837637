import React, { useState } from "react";
import { useMyTeamContext } from "../hooks/useMyTeamContext";
import beitarImage from "../images/beitarFantasy.png";
import maccabiImage from "../images/maccabFantasy.png";
import haifaImage from "../images/haifaFantasy.png";
import { Link } from "react-router-dom";

const PlayerCaracter = ({ player, location }) => {
  const {
    isOnlyDisplay,
    dispatch,
    teamBeforeSubs,
    maxSubstitutions,
    numSubsMade,
  } = useMyTeamContext();

  const removePlayer = ({ player }) => {
    // Check if the player being removed is found in teamBeforeSubs
    const foundPlayer = teamBeforeSubs.find((p) => p._id === player._id);

    if (foundPlayer && numSubsMade < maxSubstitutions) {
      console.log("foundPlayer");
      // If player is found in teamBeforeSubs, increment numSubsMade
      dispatch({ type: "INCREASE_NUM_SUBS_MADE" });
      dispatch({ type: "REMOVE_PLAYER", payload: player });
    }

    if (!foundPlayer) {
      console.log("!foundPlayer");
      // If player is not found in teamBeforeSubs, remove player from team
      dispatch({ type: "REMOVE_PLAYER", payload: player });
    }
  };

  return (
    <div
      className={`flex flex-col m-x-2 relative ${
        location === "end"
          ? "items-end"
          : location === "center" && "items-center"
      }`}
    >
      <div className={`h-[50px]`}>
        {isOnlyDisplay ? (
          <div className="rounded-full bg-gray-500 h-6 w-8 flex items-center justify-center">
            <div>
              <h1 className="text-white">{player.lastMatchPoints}+</h1>
            </div>
          </div>
        ) : (
          <div>
            {player.position === "שוער" ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  console.log("remove player");
                  removePlayer({ player });
                }}
                className="bg-black text-white hover:text-yellow rounded-full h-6 w-8 flex items-center justify-center"
              >
                x
              </button>
            )}
          </div>
        )}

        <img
          className={`w-[60px]`}
          src={`${
            player.team === "65c3b42c06c0c7a1191c65f8"
              ? maccabiImage
              : player.team === "65c3b16606c0c7a1191c6575"
              ? haifaImage
              : player.team === "65c3addd06c0c7a1191c6515" && beitarImage
          }`}
          alt="My SVG"
        />
      </div>

      <div className="w-full  ">
        <div
          className={`text-black flex flex-col m-2 ${
            location === "end"
              ? "items-end "
              : location === "center" && "items-center"
          } ${player.position === "שוער" && "flex "} `}
        >
          <div className={`overflow-x-auto `}>
            <div
              className={`bg-green-900 border-white border rounded-lg w-[80px] overflow-hidden whitespace-nowrap flex flex-col ${
                location === "end"
                  ? "items-end"
                  : location === "center" && "items-center"
              }  `}
            >
              <Link
                to={`/profile/${player._id}`}
                className={`text-white overflow-hidden  ${
                  location === "end" ? "ml-1" : location === "start" && "mr-1"
                }`}
                style={{ textOverflow: "ellipsis" }}
              >
                {player.name}
              </Link>
            </div>
          </div>
          {player.position === "שוער" && !isOnlyDisplay && (
            <div className="relative">
              <div className="absolute  bg-black hover:text-yellow rounded-full bottom-6 right-0 h-6 w-8 flex items-center justify-center">
                <button
                  onClick={() => {
                    console.log("kmkm");
                    removePlayer({ player });
                  }}
                  className="text-white"
                >
                  x
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerCaracter;
