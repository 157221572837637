import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDataContext } from '../hooks/useDataContext' 
import { useFetchData } from '../hooks/useFetchData'
import beitarImage from '../images/beitarFantasy.png'
import maccabiImage from '../images/maccabFantasy.png'
import haifaImage from '../images/haifaFantasy.png'

function ProfileAndStatsPage() {
  const { playerId } = useParams()
  const { teams } = useDataContext()
  const {  fetchAllPlayers } = useFetchData()
  useEffect(() => {
    const fetchDataById = async () => {
      if (!player) {
        await fetchAllPlayers();
      }
   
    };
    fetchDataById();
  }, [ fetchAllPlayers]);

  // Find the player in the teams data
  const player = teams
    .flatMap((team) => team.players)
    .find((player) => player._id === playerId)

  if (!player) {
    return <div>Player not found</div>
  }

  const playerTeam =
    player.team === '65c3b42c06c0c7a1191c65f8'
      ? 'מכבי תל אביב'
      : player.team === '65c3b16606c0c7a1191c6575'
      ? 'מכבי חיפה'
      : player.team === '65c3addd06c0c7a1191c6515'
      ? 'בית״ר ירושלים'
      : '' // Default value if none of the conditions are met

  // Extract player stats
  const { totalPoints, lastMatchPoints } = player
  const { name, position, price } = player

  return (
    <div className='flex justify-center items-center min-h-screen  '>
      <div className='bg-black shadow-lg rounded-lg p-6 max-w-md text-white w-5/6'>
        <div>
          <h1 className=' w-6/7 bg-customBlue flex justify-center rounded-md mx-auto text-xl'>
            פרופיל שחקן
          </h1>
        </div>
        <div className=' flex mt-4  w-6/7 mx-auto '>
          <div className=' w-2/3'>
            <h2 className='text-xl font-bold mb-2 '>{name}</h2>
            <h1 className=' md:mb-1'>{price}M</h1>
            <h1 className=' md:mb-1'>{position}</h1>
            <h1>{playerTeam}</h1>
          </div>
          <div className=' w-1/3  '>
            <img
              className={``}
              src={`${
                player.team === '65c3b42c06c0c7a1191c65f8'
                  ? maccabiImage
                  : player.team === '65c3b16606c0c7a1191c6575'
                  ? haifaImage
                  : player.team === '65c3addd06c0c7a1191c6515' && beitarImage
              }`}
              alt='My SVG'
            />
          </div>
        </div>
        <div className=' w-6/7 mx auto mt-2 flex justify-between'>
          <div className=' w-3/5  flex border-2 border-customBlue'>
            <div className=' w-4/5'>
              <h1 className=' border-l border-customBlue mr-1'>
                נקודות מחזור אחרון
              </h1>
            </div>
            <div className=' w-1/5 flex justify-center bg-customBlue'>
              {' '}
              <h1 className=''>{lastMatchPoints}</h1>{' '}
            </div>
          </div>
          <div className='w-2/5 flex border-2 border-customBlue mr-3 '>
            <div className=' w-4/5'>
              <h1 className=' border-l border-customBlue mr-1'>נקודות העונה</h1>
            </div>
            <div className='  w-1/5 flex justify-center bg-customBlue'>
              {' '}
              <h1>{totalPoints}</h1>{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileAndStatsPage
