import { useState } from "react";
import { useAuthContext } from "./UseAuthContext";
import { useMyTeamContext } from "./useMyTeamContext";
import { useLeaguesContext } from "./useLeaguesContext";

export const useCreate = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { user, dispatch } = useAuthContext();
  const { dispatchLeague } = useLeaguesContext();
  const {
    team,
    coachOfTeam,
    teamName,
    budget,
    numSubsMade,
    dispatch: dispatchUserTeam,
  } = useMyTeamContext();

  //create a new team of user
  const createTeam = async () => {
    setIsLoading(true);
    console.log("created");
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/userteams/create`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          team,
          teamName,
          coachOfTeam,
          budget,
          userMail: user.email,
        }),
      }
    );
    const json = await response.json();

    //update local storage that user has created team
    const userLocal = JSON.parse(localStorage.getItem("user"));
    userLocal.teamOfUser = json._id;
    localStorage.setItem("user", JSON.stringify(userLocal));
  };

  // create a new league
  const createLeague = async ({ leagueName }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/league/create`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: leagueName,
          userMail: user.email,
        }),
      }
    );
    const json = await response.json();
    if (response.ok) {
      console.log(json);
    }
    dispatchLeague({ type: "CREATED_LEAGUE_ID", payload: json._id });
  };

  const JoinLeagueFunction = async ({ leagueId }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/league/join`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            leagueId: leagueId,
            userMail: user.email,
          }),
        }
      );

      if (!response.ok) {
        // If the status is 410, log something
        if (response.status === 410) {
          dispatchLeague({
            type: "SET_ERROR",
            payload: "הקבוצה שלך כבר בליגה הזו",
          });
        }
        if (response.status === 410) {
          dispatchLeague({
            type: "SET_ERROR",
            payload: "ליגה זו לא נמצאה",
          });
        }
        if (response.status === 401) {
          dispatchLeague({
            type: "SET_ERROR",
            payload: "קוד ליגה לא תקין",
          });
        }

        // Throw an error to stop further execution
        throw new Error("Failed to join league");
      }

      // Parse response data if successful
      const jsonData = await response.json();
      console.log(response.status);
      if (response.status === 201) {
        dispatchLeague({
          type: "SET_MSG",
          payload: "קבוצתך צורפה לליגה",
        });
      }
    } catch (error) {
      dispatchLeague({
        type: "SET_ERROR",
        payload: "קוד ליגה לא תקין",
      });
    }
  };

  const updateTeam = async () => {
    setIsLoading(true);

    try {
      // Make a request to update the user's team
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/userteams/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            team,
            budget,
            numSubsMade,
            userMail: user.email,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update user team");
      }

      // Parse the response JSON data
      const responseData = await response.json();
      console.log(responseData);
      const updatedUserTeam = responseData.userTeam;

      // Dispatch the updated user team to your context or state management
      dispatchUserTeam({ type: "SET_USER_TEAM", payload: updatedUserTeam });
      dispatchUserTeam({
        type: "SET_SUBS_WEEK",
        payload: updatedUserTeam.numSubsThisWeek,
      });

      // Update local storage to reflect the changes
      const userLocal = JSON.parse(localStorage.getItem("user"));
      userLocal.team = team;
      userLocal.budget = budget;
      localStorage.setItem("user", JSON.stringify(userLocal));
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating team:", error.message);
    }
  };

  return { createTeam, createLeague, JoinLeagueFunction, updateTeam, error };
};
