import React from 'react'
import { useMyTeamContext } from '../hooks/useMyTeamContext'
import { TfiMoney } from 'react-icons/tfi'

const StatusOfTeam = () => {
  const { budget } = useMyTeamContext()
  return (
    <div className=' w-full flex justify-center  bg-black  mt-1 text-white rounded-md'>
      <h1 className=' ml-2 text-customBlue'>תקציב שנשאר</h1>
      <TfiMoney className=' mt-1' />
      <h1 className=''>M</h1>
      <h1 className=''> {budget} </h1>
      <h1 className='mr-1 text-customBlue'>/</h1>
      <TfiMoney className='text-customBlue mt-1' />
      <h1 className='text-customBlue '>M</h1>
      <h1 className='text-customBlue'> 120</h1>
    </div>
  )
}

export default StatusOfTeam
