import { createContext, useReducer } from "react";

const initialState = {
  teamName: null,
  coachOfTeam: null,
  team: [],
  teamBeforeSubs: [],
  budget: 120,
  totalPoints: 0,
  isTeamForSubs: false,
  isTeamForBuilt: false,
  isMaxSubs: false,
  numSubsMade: 0,
  isOnlyDisplay: true,
  numSubsThisWeek: 0,
  allowSubstitutions: false,
  msg: "",
};

function myTeamReducer(state, action) {
  switch (action.type) {
    case "SET_TEAM":
      return {
        team: action.payload.players,
        coachOfTeam: action.payload.coachOfTeam,
        built: true,
        totalPoints: action.payload.totalPoints,
        teamName: action.payload.teamName,
        budget: 120 - action.payload.budget,
        isTeamForSubs: false,
      };
    case "SET_TEAM_FOR_SUBS":
      console.log(action.payload);
      return {
        // ...state,
        team: action.payload.team.players,
        teamBeforeSubs: action.payload.team.players,
        isTeamForSubs: true,
        allowSubstitutions: action.payload.substitutionData.allowSubstitutions,
        maxSubstitutions: action.payload.substitutionData.maxSubstitutions,
        numSubsThisWeek: action.payload.team.numSubsThisWeek,
        endDate: action.payload.substitutionData.endDate,
        budget: action.payload.team.budget,
        numSubsMade: 0,
      };
    case "SET_TEAM_NAME":
      return {
        ...state,
        teamName: action.payload,
      };
    case "SET_TEAM_COACH":
      return {
        ...state,
        coachOfTeam: action.payload,
      };
    case "SET_TEAM_SETUP":
      return {
        ...state,
        setup: action.payload,
      };

    case "ADD_PLAYER":
      return {
        ...state,
        team: [...state.team, action.payload],
        budget: state.budget - action.payload.price,
      };

    case "REMOVE_PLAYER":
      console.log("remove player context func");
      return {
        ...state,
        team: state.team.filter((player) => player._id !== action.payload._id),
        budget: state.budget + action.payload.price,
      };

    case "RESET_TEAM":
      return {
        teamName: null,
        coachOfTeam: null,
        team: [],
        budget: 120,
      };

    case "SET_MAX_SUBS":
      return {
        ...state,
        isMaxSubs: action.payload,
      };
    case "INCREASE_NUM_SUBS_MADE":
      return {
        ...state,
        numSubsMade: state.numSubsMade + 1,
      };
    case "REDUCE_NUM_SUBS_MADE":
      console.log("reduce num subs made");
      return {
        ...state,
        numSubsMade: state.numSubsMade - 1,
      };
    case "SET_ONLY_DISPLAY":
      console.log("set only display");
      return {
        ...state,
        isOnlyDisplay: action.payload,
      };
    case "SET_TEAM_FOR_BUILT":
      return {
        ...state,
        isTeamForBuilt: action.payload,
      };
    case "SET_MSG":
      return {
        ...state,
        msg: action.payload,
      };
    case "SET_SUBS_WEEK":
      return {
        ...state,
        numSubsThisWeek: action.payload,
      };
    default:
      return state;
  }
}

export const MyTeamContext = createContext(initialState);
console.log("MyTeamContext:", MyTeamContext);
export function MyTeamProvider({ children }) {
  const [state, dispatch] = useReducer(myTeamReducer, initialState);
  console.log("MyTeamContext state:", state);
  return (
    <MyTeamContext.Provider value={{ ...state, dispatch }}>
      {children}
    </MyTeamContext.Provider>
  );
}
