import { useState } from "react";
import { useCreate } from "../hooks/useCreate";
import { useLeaguesContext } from "../hooks/useLeaguesContext";
import { ClipLoader } from "react-spinners";

function JoinLeague() {
  const [leagueId, setLeagueId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { error, msg } = useLeaguesContext();
  const { JoinLeagueFunction } = useCreate();

  const submitLeagueCode = async () => {
    setIsLoading(true);
    await JoinLeagueFunction({ leagueId });
    setIsLoading(false);
    // window.location.reload();
  };

  return (
    <div className="rtl bg-gray-200 px-6 py-4 shadow-lg">
      <div className="flex items-center justify-between mb-4">
        <input
          type="text"
          placeholder=" הדבק כאן את קוד הליגה   "
          className="rtl bg-white  py-2 px-4 focus:outline-none focus:shadow-outline w-full"
          onChange={(e) => setLeagueId(e.target.value)}
        />
        <button
          onClick={submitLeagueCode}
          className="bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] hover:bg-blue-700 text-white font-bold py-2 px-4 relative" // Add relative position for spinner alignment
        >
          {isLoading ? (
            <ClipLoader size={18} color="white" margin={3} />
          ) : (
            "אישור"
          )}
        </button>
      </div>
      {error && <p className="text-red ">{error}</p>}
      {msg && <p className="text-green ">{msg}</p>}
    </div>
  );
}

export default JoinLeague;
