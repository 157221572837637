import { useContext } from "react";
import { DataContext } from "../context/DataContext";
import { useAuthContext } from "../hooks/UseAuthContext";
import { useMyTeamContext } from "./useMyTeamContext";
import { useLeaguesContext } from "../hooks/useLeaguesContext";

export const useFetchData = () => {
  const { dispatch } = useContext(DataContext);
  const { dispatchLeague } = useLeaguesContext();
  const { dispatch: dispatchUserTeam } = useMyTeamContext();
  const { user } = useAuthContext();

  //fetching all players for user  that user could pick his own team
  const fetchAllPlayers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/team/allteams`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const data = await response.json();
      console.log("players", data);

      dispatch({ type: "SET_DATA", payload: data });
    } catch (error) {
      console.error(error);
    }
  };

  // fetch all the teams created by the users for main league data
  const fetchAllUsersTeams = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/userteams/allusersteams`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const data = await response.json();
      // console.log(data)
      dispatchLeague({ type: "SET_MAIN_LEAGUE_DATA", payload: data });
    } catch (error) {
      console.error(error);
    }
  };

  // fetch specific user team by mail
  const fetchUserTeamByMail = async (email) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/userteams/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ userMail: email }),
      }
    );
    const json = await response.json();
    console.log(json);
    if (response.ok) {
      // update the team context
      dispatchUserTeam({ type: "SET_TEAM", payload: json });
      dispatchUserTeam({ type: "SET_ONLY_DISPLAY", payload: true });
    }
  };

  const fetchUserTeamForSub = async (email) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/substitution/subsdata`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userMail: email }),
        }
      );
      console.log(`${process.env.REACT_APP_BASE_URL}/substitution/subsdata`);
      if (!response.ok) {
        throw new Error("Failed to fetch user team");
      }

      const data = await response.json();
      console.log("data", data);
      // console.log(data.team.numSubsThisWeek);
      dispatchUserTeam({ type: "SET_TEAM_FOR_SUBS", payload: data });
    } catch (error) {
      console.error("Error fetching user team:", error);
      throw error;
    }
  };

  // fetch specific user team by id
  const fetchUserTeamById = async (_id) => {
    console.log(_id);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/userteams/${_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();
    console.log(json);
    if (response.ok) {
      // update the team context
      dispatchUserTeam({ type: "SET_TEAM", payload: json });
      dispatchUserTeam({ type: "SET_ONLY_DISPLAY", payload: true });
    }
  };

  // fetch all the leagues  user in
  const fetchUserLeagues = async () => {
    // console.log(user.email)
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/league/findleagues`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userMail: user.email }),
      }
    );
    const json = await response.json();
    // console.log(json)
    if (response.ok) {
      dispatchLeague({ type: "SET_USER_LEAGUE_DATA", payload: json.leagues });
    }
  };

  // fetch specific leagues
  const fetchSpecificLeague = async (_id) => {
    // console.log(user.email)
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/league/${_id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    const json = await response.json();
    console.log(json);
    if (response.ok) {
      dispatchLeague({
        type: "SET_SPECIFIC_LEAGUE_DATA",
        payload: json,
      });
    }
  };

  return {
    fetchAllPlayers,
    fetchAllUsersTeams,
    fetchUserLeagues,
    fetchUserTeamByMail,
    fetchSpecificLeague,
    fetchUserTeamById,
    fetchUserTeamForSub,
  };
};
