import React, { useState, useEffect } from "react";

const CountdownTimer = ({ endDate }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(endDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        ימים: Math.floor(difference / (1000 * 60 * 60 * 24)),
        שעות: Math.floor((difference / (1000 * 60 * 60)) % 24),
        דקות: Math.floor((difference / 1000 / 60) % 60),
        שניות: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval} className="mr-2">
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className="bg-green-500 border border-2-black p-2 rounded-md">
      <div className="mr-2">
        <h1>זמן לסגירת החלון: </h1>{" "}
      </div>
      <div>{timerComponents}</div>
    </div>
  );
};

export default CountdownTimer;
