import React, { useEffect } from "react";
import Pitch from "../components/SoccerPitch";
import PickPlayers from "../components/PickPlayers";
import PlayersOnPitch from "../components/PlayersOnPitch";
import { useAuthContext } from "../hooks/UseAuthContext";
import { useFetchData } from "../hooks/useFetchData";
import { useMyTeamContext } from "../hooks/useMyTeamContext";

import CountdownTimer from "../components/CountdownTimer";

const SubstitutionPage = () => {
  const { user } = useAuthContext();
  const { dispatch } = useMyTeamContext();
  const { fetchUserTeamForSub } = useFetchData();
  const {
    maxSubstitutions,
    endDate,
    numSubsMade,
    numSubsThisWeek,
    msg,
    allowSubstitutions,
  } = useMyTeamContext();

  useEffect(() => {
    console.log(allowSubstitutions);
    const fetchDataByMail = async () => {
      dispatch({ type: "SET_ONLY_DISPLAY", payload: false });
      console.log("user.email", user.email);
      await fetchUserTeamForSub(user.email);
    };

    fetchDataByMail();
  }, [numSubsThisWeek]);
  if (!allowSubstitutions) {
    return (
      <div className="z-14 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white p-4 rounded-lg h-[220px] w-5/6 md:w-1/2 bg-black gap-5 flex justify-center items-center flex-col ">
        חלון החילופים סגור כרגע
      </div>
    );
  }
  if (user.teamOfUser === null) {
    return (
      <div className="z-14 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white p-4 rounded-lg h-[220px] w-5/6 md:w-1/2 bg-black gap-5 flex justify-center items-center flex-col ">
        יש לבנות קבוצה
      </div>
    );
  }
  if (numSubsThisWeek >= maxSubstitutions) {
    console.log(numSubsThisWeek);
    return (
      <div className=" flex flex-col justify-center h-screen">
        <div
          class="bg-teal-100 border-t-4 border-teal-500 mx-auto w-5/6 rounded-b text-teal-900 px-4 py-3 shadow-md mt md:pt-[50px] max-md:pt-4"
          role="alert"
        >
          <div class="flex">
            <div class="py-1">
              <svg
                class="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p class="font-bold">{" הגעת למכסת החלופים השבועית "}</p>

              <p class="text-sm">ניתן לבצע חילופים נוספים החל מהמחזור הבא </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="md:pt-[50px] max-md:pt-4">
        <div className="md:flex mt-10">
          <div className="w-full order-2">
            <div className="max-md:mt-16">
              <div className="text-center mt-4 w-[350px] mx-auto mb-3">
                <CountdownTimer endDate={endDate} />
              </div>
              <div className="relative w-[350px] h-[600px] mx-auto">
                <Pitch />
                <div className="bg-green-500 mt-1 w-full mx-auto border border-black justify-center flex">
                  <h1 className="ml-2">חילופים:</h1>
                  {maxSubstitutions} /{numSubsMade}
                </div>
                <div className="absolute top-0 -left-0">
                  <PlayersOnPitch />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full order-1 mt-10 max-md:mt-10">
            <div className="">
              <PickPlayers />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubstitutionPage;
