import { useState } from "react";
import { useSignup } from "../hooks/useSignUp";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { signup, isLoading, error: serverError } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("נא מלא את כל השדות");
      return;
    }

    if (password.length < 4) {
      setError("הסיסמה חייבת להכיל לפחות 4 תווים");
      return;
    } else {
      setError("");
    }

    await signup(email, password);
  };

  return (
    <form className="min-h-screen min-w-max" onSubmit={handleSubmit}>
      <div className="py-6 sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto w-5/6 mx-auto mt-32 md:mt-20">
          <div className="absolute inset-0 bg-gradient-to-tr from-blue to-red shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <div className="max-w-md mx-auto">
              <div>
                <h1 className="text-2xl font-semibold">
                  הרשם עם אימייל וסיסמא
                </h1>
              </div>
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div className="relative ">
                    <input
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      name="email"
                      type="email"
                      className={`peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none `}
                      placeholder="כתובת מייל"
                    />
                  </div>
                  <div className="relative">
                    <input
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      type="password"
                      className={`peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none`}
                      placeholder="סיסמא"
                    />
                  </div>
                  <div className="relative flex items-center">
                    <button
                      className="bg-black text-white rounded-md px-2 py-1 w-2/5 hover:px-3"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <ClipLoader size={18} color="white" margin={3} />
                      ) : (
                        "הרשם"
                      )}
                    </button>
                  </div>
                  <div className="text-center mt-4">
                    <p className="text-gray-700">
                      כבר רשום?{" "}
                      <Link
                        to="/login"
                        className="text-green-500 hover:underline"
                      >
                        התחבר כאן
                      </Link>
                    </p>
                  </div>
                  {error && (
                    <div className="text-red-500 text-center mt-4 text-rose-600">
                      {error}
                    </div>
                  )}
                  {serverError && (
                    <div className="text-red-500 text-center mt-4 text-rose-600">
                      {serverError}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Signup;
