import { useState } from 'react'
import { useAuthContext } from './UseAuthContext'

export const useLogin = () => {
  const [serverError, setsSrverError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch, user } = useAuthContext()

  const login = async (email, password) => {
    setIsLoading(true)
    setsSrverError(null)

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/user/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      }
    )
    const json = await response.json()

    if (!response.ok) {
      console.log(json.error)
      setIsLoading(false)
      setsSrverError(json.error)
    }
    if (response.ok) {
      console.log(json)

      // save the user to local storage
      localStorage.setItem('user', JSON.stringify(json))

      // update the auth context
      dispatch({ type: 'LOGIN', payload: json })

      // update loading state
      setIsLoading(false)
    }
  }

  return { login, isLoading, serverError }
}
