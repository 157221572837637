import { useState } from "react";
import { useCreate } from "../hooks/useCreate";
import { useLeaguesContext } from "../hooks/useLeaguesContext";
import { ClipLoader } from "react-spinners";
import { useFetchData } from "../hooks/useFetchData";

function CreateLeague() {
  const [leagueName, setLeagueName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { fetchAllUsersTeams } = useFetchData();
  const [errorMessage, setErrorMessage] = useState("");
  const { createLeague } = useCreate();
  const { leagueId, createdLeagueId } = useLeaguesContext();

  const submitLeagueName = async () => {
    if (leagueName.trim() === "") {
      setErrorMessage("שם הליגה לא יכול להיות ריק");
      return;
    }

    setIsLoading(true);
    await createLeague({ leagueName });
    setIsLoading(false);
    setErrorMessage("");
  };

  return (
    <div className="rtl px-6 py-4 bg-gray-200 shadow-lg">
      <div className="flex items-center justify-between mb-4">
        <input
          type="text"
          placeholder=" כתוב את שם הליגה"
          className="rtl bg-white py-2 px-4 focus:outline-none focus:shadow-outline w-full"
          value={leagueName}
          onChange={(e) => {
            setLeagueName(e.target.value);
            setErrorMessage(""); // Clear error message when input changes
          }}
        />

        <button
          onClick={submitLeagueName}
          className="bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] hover:bg-blue-700 text-white font-bold py-2 px-4 relative"
        >
          {isLoading ? (
            <ClipLoader size={18} color="white" margin={3} />
          ) : (
            "אישור"
          )}
        </button>
      </div>
      <div className="text-right ">
        {errorMessage && (
          <p className="    ">{errorMessage}</p> // Display error message if exists
        )}
        <p>
          {createdLeagueId
            ? createdLeagueId
            : "לאחר בניית הליגה יופיע קוד הליגה"}{" "}
        </p>
      </div>
    </div>
  );
}

export default CreateLeague;
