import Pitch from "../components/SoccerPitch";
import PlayersOnPitch from "../components/PlayersOnPitch";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../hooks/UseAuthContext";
import { useEffect } from "react";
import { useMyTeamContext } from "../hooks/useMyTeamContext";
import { useFetchData } from "../hooks/useFetchData";

const UserTeamPage = () => {
  const { fetchUserTeamByMail, fetchUserTeamById, fetchAllPlayers } =
    useFetchData();

  const { user } = useAuthContext();

  let { _id } = useParams();
  const email = user.email;
  const { totalPoints, coachOfTeam, budget, teamName } = useMyTeamContext();

  useEffect(() => {
    const fetchDataByMail = async () => {
      if (user.teamOfUser) await fetchUserTeamByMail(email);
    };
    const fetchDataById = async () => {
      await fetchUserTeamById(_id);
      await fetchAllPlayers();
    };

    //fetch only if user created the team
    if (_id === undefined && user.teamOfUser !== null) {
      console.log("fetch team of user");
      fetchDataByMail();
    }

    //fetch team of others
    if (_id !== undefined) {
      console.log("fetch team of others");

      fetchDataById();
    }
  }, [_id]);

  return (
    <div className=" w-full md:w-5/6 m-auto pb-10 pt-20  ">
      <div className="w-full  mt-3  ">
        <div>
          <div className=" h-[30px] bg-green-500  flex justify-center w-4/5 md:w-2/5 mx-auto mb-2 border-1  border-black overflow-hidden  border-2   ">
            <h1 className=" text-black text-xl">{teamName}</h1>
          </div>
          <div className="relative w-[350px]  h-[600px]  mx-auto">
            <Pitch />
            <div className=" absolute top-0 -left-0">
              <PlayersOnPitch />
            </div>
          </div>
        </div>
      </div>
      <div className=" w-4/5 mx-auto  rounded-lg  mt-1  md:mt-3 md:w-1/3 ">
        <div className=" w-5/6 mx-auto h-auto">
          <div className=" h-[30px]  flex justify-center w-full mx-auto mb-2 border-1 border-black  "></div>
          <div className=" h-[30px]  flex justify-center w-full mx-auto mb-2 border-1 border-black border-2 overflow-hidden   bg-green-500 ">
            <h1 className=" text-black text-xl">הקבוצה של </h1>
            <h1 className=" text-black text-xl mr-2">{coachOfTeam}</h1>
          </div>
          <div className=" w-full  mx-auto mt-2 rounded-md  border-2 border-white flex ">
            <div className=" w-2/3 bg-white">
              <h1 className=" mr-2 text-customBlue">סך הנקודות לקבוצה</h1>
            </div>
            <div className="bg-newGreen w-1/3  rounded-l-md flex justify-center text-white">
              {" "}
              {totalPoints}
            </div>
          </div>
          <div className=" w-full  mx-auto mt-2 rounded-md  border-2 border-white flex ">
            <div className=" w-2/3 bg-white">
              <h1 className=" mr-2 text-customBlue"> תקציב בשימוש </h1>
            </div>
            <div className="bg-newGreen w-1/3  rounded-l-md flex justify-center text-white">
              {" "}
              {budget}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTeamPage;
