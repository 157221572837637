import { useAuthContext } from "./UseAuthContext";

export const useLogout = () => {
  const { dispatch } = useAuthContext();

  const logout = () => {
    // Remove user from storage
    localStorage.removeItem("user");

    // Dispatch logout action
    dispatch({ type: "LOGOUT" });

    // Navigate to home page
    window.location.href = "/"; // Replace '/' with your home page URL
  };

  return { logout };
};
