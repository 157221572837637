import React from "react";

const RulesPage = () => {
  return (
    <div className="container  pt-24   py-8 w-5/6 mx-auto">
      <div className=" bg-white rounded-md p-5">
        <h1 className="text-3xl font-bold mb-4">חוקי המשחק של ליגת החלומות</h1>
        <p className="text-lg mb-6">
          ברוכים הבאים לליגת החלומות של יגאל ליפסי, משחק הכדורגל הווירטואלי
          שמאפשר לכם להרכיב, לנהל ולאמן קבוצת כדורגל מתוך מאגר שחקני כל הקבוצות
          המשתתפות בליגת העל בכדורגל.
        </p>
        <h2 className="text-2xl font-bold mb-2">תקציב</h2>
        <p className="mb-4">
          כל משתתף מקבל תקציב דמיוני של 120 מיליון שקל איתו הוא מרכיב נבחרת בת
          11 שחקנים שצוברים נקודות על סמך ביצועים על המגרש.
        </p>
        <h2 className="text-2xl font-bold mb-2">חלוקת הניקוד</h2>
        <p className="mb-4">
          כל שחקן מקבל ניקוד בהתאם לביצועי השחקן האמיתי במגרש. לדוגמה:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>שחקן ששיחק במשחק פחות מ-60 דקות – 1 נקודה</li>
          <li>שחקן ששיחק לפחות 60 דקות – 2 נקודות</li>
          <li>שער שנכבש על ידי שוער – 7 נקודות</li>
          <li>
            בונוס על כיבוש שערים: צמד = נקודת בונוס. שלושער = 2 נקודות בונוס.
            רביעייה = 3 נקודות בונוס, וכן הלאה
          </li>
          <li>בישול (לא כולל שער עצמי) – 3 נקודות</li>
          <li>
            שוער שלא ספג (בהנחה ושיחק לפחות 60 דקות, לא כולל תוספת זמן) – 4
            נקודות
          </li>
          <li>
            מגן שלא ספג (בהנחה ושיחק לפחות 60 דקות, לא כולל תוספת זמן) – 4
            נקודות
          </li>
          <li>שחקן שגרם לפנדל (כולל נגיעת יד) – 4- נקודות</li>
          <li>שחקן שסחט פנדל (כולל בעיטה/הרמה שנעצרה ביד) – 2 נקודות</li>
          <li>שוער שעצר פנדל (רק במידה והדף) – 4 נקודות</li>
          <li>שחקן שהחטיא פנדל – -4 נקודות</li>
          <li>שחקן שכבש שער עצמי – -5 נקודות</li>
          <li>כרטיס צהוב – -1 נקודות</li>
          <li>שני כרטיסים צהובים שמובילים להרחקה – -3 נקודות</li>
          <li>כרטיס אדום – -3 נקודות</li>
          <li>כרטיס צהוב + כרטיס אדום ישיר – -4 נקודות</li>
          <li>
            שחקני הגנה/שוער – ספיגת שער אחד מבטלת את הבונוס, החל מהשער השני,
            מינוס נקודה לכל שער
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RulesPage;
