import { useState } from "react";
import { useMyTeamContext } from "../hooks/useMyTeamContext";
import { useDataContext } from "../hooks/useDataContext";
import { GiTwoCoins } from "react-icons/gi";
import { TfiMoney } from "react-icons/tfi";
import { Link } from "react-router-dom";
import ErrorMsg from "./ErrorMsg";

const SelectPlayerByFilter = () => {
  const { teams, positionToFilter, teamToFilter, priceToFilter } =
    useDataContext();

  return (
    <div className="bg-opaque-black">
      {/* Map over each team in the teams array */}
      {teams.map((team) =>
        // If no teamToFilter is selected, display all players
        teamToFilter === null ? (
          <div key={team.name}>
            <ul>
              {/* Map over each player in the team's players array */}
              {team.players.map((player) =>
                // If no positionToFilter or priceToFilter is selected, display all players
                positionToFilter === null ? (
                  priceToFilter === null ? (
                    <Player key={player._id} player={player} />
                  ) : (
                    // If a priceToFilter is selected, display players that match the price
                    priceToFilter === player.price && (
                      <Player key={player._id} player={player} />
                    )
                  )
                ) : (
                  // If a positionToFilter is selected, display players that match the position
                  positionToFilter === player.position &&
                  (priceToFilter === null ? (
                    <Player key={player._id} player={player} />
                  ) : (
                    // If both a positionToFilter and priceToFilter are selected, display players that match both
                    priceToFilter === player.price && (
                      <Player key={player._id} player={player} />
                    )
                  ))
                )
              )}
            </ul>
          </div>
        ) : (
          // If a teamToFilter is selected, only display players from that team
          teamToFilter === team.name && (
            <div key={team.name}>
              {team.players.map((player) =>
                // Same filtering logic as above
                positionToFilter === null ? (
                  priceToFilter === null ? (
                    <Player key={player._id} player={player} />
                  ) : (
                    priceToFilter === player.price && (
                      <Player key={player._id} player={player} />
                    )
                  )
                ) : (
                  positionToFilter === player.position &&
                  (priceToFilter === null ? (
                    <Player key={player._id} player={player} />
                  ) : (
                    priceToFilter === player.price && (
                      <Player key={player._id} player={player} />
                    )
                  ))
                )
              )}
            </div>
          )
        )
      )}
    </div>
  );
};

function Player({ player }) {
  const [errors, setErrors] = useState([]);

  const {
    dispatch,
    team,
    budget,
    isTeamForSubs,
    numSubsMade,
    maxSubstitutions,
    teamBeforeSubs,
    isMaxSubs,
  } = useMyTeamContext();

  const selectPlayer = ({ player }) => {
    const playersFromSelectedTeam = team.filter((p) => p.team === player.team);
    if (playersFromSelectedTeam.length >= 4) {
      setErrors((prevErrors) => [
        ...prevErrors,
        "לא ניתן לבחור מעל ל4 שחקנים מאותה הקבוצה ",
      ]);
      return;
    }

    const attackPlayers = team.filter((player) => player.position === "התקפה");
    const midfielderPlayers = team.filter(
      (player) => player.position === "קישור"
    );
    const defencePlayers = team.filter((player) => player.position === "הגנה");
    const goalkeeperPlayers = team.filter(
      (player) => player.position === "שוער"
    );

    if (budget - player.price < 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        "לא ניתן לבחור שחקן בחריגה מהתקציב ",
      ]);
      return;
    }

    if (team.length >= 11) {
      console.log("if");
      setErrors((prevErrors) => [...prevErrors, "נבחרו 11 שחקנים.."]);
      return;
    }

    if (player.position === "שוער" && goalkeeperPlayers.length >= 1) {
      setErrors((prevErrors) => [...prevErrors, "ניתן לבחור רק שוער אחד"]);
      return;
    }

    if (player.position === "הגנה" && defencePlayers.length >= 5) {
      setErrors((prevErrors) => [...prevErrors, "ניתן לבחור עד 5 שחקני הגנה"]);
      return;
    }
    if (player.position === "קישור" && midfielderPlayers.length >= 5) {
      setErrors((prevErrors) => [...prevErrors, "ניתן לבחור עד 5 שחקני קישור"]);
      return;
    }
    if (player.position === "התקפה" && attackPlayers.length >= 3) {
      setErrors((prevErrors) => [...prevErrors, "ניתן לבחור עד 3 שחקני התקפה"]);
      return;
    }
    const clearErrors = () => {
      setErrors([]);
    };
    clearErrors();

    if (!isTeamForSubs) {
      dispatch({ type: "ADD_PLAYER", payload: player });
    }

    if (isTeamForSubs) {
      const foundPlayer = teamBeforeSubs.find((p) => p._id === player._id);
      if (foundPlayer) {
        console.log("foundPlayer");
        dispatch({ type: "REDUCE_NUM_SUBS_MADE" });
        dispatch({ type: "ADD_PLAYER", payload: player });
      } else {
        dispatch({ type: "ADD_PLAYER", payload: player });
      }
    }
  };

  const removePlayer = ({ player }) => {
    if (!isTeamForSubs) {
      console.log("shone");
      dispatch({ type: "REMOVE_PLAYER", payload: player });
    } else {
      console.log("lo shone");
    }
  };

  return (
    <>
      <div className={`mt-1 flex py-2 border-t text-white `}>
        <div className=" w-3/6  ">
          <h1
            className={`mr-1 ${
              team.some((p) => p._id === player._id) ? "text-orange-500" : ""
            }`}
          >
            {player.name}
          </h1>
        </div>
        <div className="w-1/6 flex justify-center border-r">
          <Link to={`/profile/${player._id}`}>פרופיל</Link>
        </div>

        <div className=" w-1/6 border-x flex ">
          <div className=" w-1/3 ">
            <GiTwoCoins className=" mr-3 mt-1" />
          </div>
          <div className=" w-2/3 flex">
            <TfiMoney className=" mt-1" />
            <h1 className=" ml-1">{player.price}M</h1>
          </div>
        </div>

        <div className=" w-1/6">
          <button
            className={` w-full ${
              team.some((p) => p._id === player._id)
                ? "hover:text-rose-700"
                : "hover:text-green-700"
            }`}
            onClick={() => selectPlayer({ player })}
            disabled={team.some((p) => p._id === player._id)}
          >
            בחר
          </button>
        </div>
      </div>
      <div>
        {errors.map((error, index) => (
          <ErrorMsg key={index} error={error} />
        ))}
        {isMaxSubs && <ErrorMsg error={"הגעת למכסת החילופים"} />}
      </div>
    </>
  );
}
export default SelectPlayerByFilter;
